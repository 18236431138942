/* Base banner styling */
.ad-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  background-color: #fff;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s ease-in-out;
}

/* Fade-in effect for entrance */
.fade-in {
  opacity: 1;
  transform: translateY(0);
}

/* Image wrapper with gradient overlay */
.ad-banner-image-wrapper {
  position: relative;
  margin:10px;
  width: 50%;
}
.ad-banner-image-wrapper-100 {
  position: relative;
  margin:10px;
  width: 100%;
}

.ad-banner-image {
  width: 100%;
  object-fit: cover;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
}

/* Content section */
.ad-banner-content {
  padding: 20px;
  width: 50%;
  text-align: left;
}

.ad-banner-content h2 {
  margin: 0 0 10px;
  font-size: 24px;
  color: #333;
}

.ad-banner-content p {
  margin: 0 0 15px;
  font-size: 16px;
  color: #555;
}

/* Button styling with hover animation */
.ad-banner-button {
  padding: 10px 20px;
  background-color: #ff6f61;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.ad-banner-button:hover {
  background-color: #e64a19;
  transform: scale(1.05);
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .ad-banner {
    flex-direction: column;
    text-align: center;
  }

  .ad-banner-image-wrapper {
    width: 100%;
    margin:10px;
  }

  .ad-banner-content {
    width: 100%;
  }

  .ad-banner-button {
    width: 100%;
    padding: 15px 0;
  }
}
